<template>
  <div>
    <div class="tab-pane-header">设置新密码</div>
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="新密码" prop="password">
        <el-input v-model="form.password" type="password" placeholder="请输入" clearable show-password />
        <!-- <div v-if="isLogin" class="tips">新密码长度不能少于6位</div> -->
      </el-form-item>
      <el-form-item label="再次输入新密码" prop="password">
        <el-input v-model="form.passwordRepeat" type="password" placeholder="请输入" clearable show-password />
        <div v-if="isLogin" class="tips">新密码长度不能少于6位</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleFinish">完成</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validatePassward, validateRequired } from '@/utils/validateRule';
import { changePasswordMsg } from '@/api/user';
import md5 from 'js-md5';
export default {
  name: 'Step2',
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
    step1Info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        password: undefined,
        passwordRepeat: undefined,
      },
      rules: {
        password: [
          { required: true, validator: validateRequired('请输入密码'), trigger: 'blur' },
          { validator: validatePassward('请输入正确的密码，密码长度6-8位,字母或者数字组成'), trigger: 'blur' },
        ],
        passwordRepeat: [
          { required: true, validator: validateRequired('请输入密码'), trigger: 'blur' },
          { validator: validatePassward('请输入正确的密码，密码长度6-8位,字母或者数字组成'), trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    retrievePwd() {
      const data = {
        userId: this.$parent.$parent.step1Info.smsCode,
        account: this.$parent.$parent.step1Info.phone,
        newPasswd: md5(this.form.password),
      };
      changePasswordMsg(data)
        .then((res) => {
          if (res) {
            this.$baseMessage('密码修改成功！', 'success');
            if (this.isLogin) {
              this.$router.push('/login');
            } else {
              this.form = {
                password: undefined,
                passwordRepeat: undefined,
              };
            }
          }
        })
        .catch((err) => err);
    },
    handleFinish() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.password !== this.form.passwordRepeat) {
            this.$baseMessage('两次密码不一致,请重新输入', 'error');
          } else {
            this.retrievePwd();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  margin-top: 8px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bcbdc0;
  line-height: 20px;
}
::v-deep {
  .el-form-item__label {
    padding-bottom: 0;
  }
}
.tab-pane-header {
  margin-bottom: 10px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #04122b;
  line-height: 22px;
}
</style>
