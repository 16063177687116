<template>
  <div class="login-wrap">
    <div class="login-card">
      <el-form ref="loginForm" class="login-form">
        <step1 v-if="step === 1" @next="handleNext" />
        <step2 v-else :step1info="step1Info" is-login />
      </el-form>
    </div>
  </div>
</template>

<script>
import Step1 from '@/views/account/step1';
import Step2 from '@/views/account/step2';
export default {
  name: 'Forget',
  components: { Step1, Step2 },
  // directives: {
  //   focus: {
  //     inserted(el) {
  //       el.querySelector('input').focus();
  //     },
  //   },
  // },
  data() {
    return {
      step1Info: undefined,
      step: 1,
    };
  },
  methods: {
    handleNext(step1Info) {
      this.step1Info = step1Info;
      this.step = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .tab-pane-header {
    margin-bottom: 32px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #272727;
    line-height: 40px;
  }
  .el-form-item__label {
    margin-bottom: 8px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #26272a;
    line-height: 22px;
  }
  .el-form-item__label:before {
    display: none;
  }
  .unit-input {
    .el-input__clear {
      padding-top: 39px;
      padding-right: 130px;
    }
  }
  .unit {
    margin-top: 15px;
    font-size: 16px;
  }
  .el-button {
    margin-top: 10px;
    width: 100%;
    height: 52px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .el-input input {
    height: 52px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .el-input__prefix {
    display: inline-flex;
    align-items: center;
    left: 10px;
  }
  .el-input__suffix {
    display: inline-flex;
    align-items: center;
  }
}
.login-wrap {
  height: 100vh;
  background: url("~@/assets/images/login/login-bg.jpg") center center fixed no-repeat;
  background-size: cover;
  .login-card {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 460px;
    overflow: hidden;
    display: flex;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    .login-form {
      position: relative;
      overflow: hidden;
      width: 460px;
      padding: 56px;
      .form-title {
        font-size: 28px;
        padding-bottom: 32px;
      }
      .forget {
        cursor: pointer;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #58595c;
        line-height: 22px;
      }
      .login-btn {
        margin-top: 25px;
        width: 100%;
        height: 52px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 10px;
    color: rgb(207, 202, 202);
    margin-left: calc(50% - 275px);
    .icp {
      cursor: pointer;
      margin-left: 5px;
      color: rgb(207, 202, 202);
      &:hover {
        color: #fff;
      }
    }
  }
}
::v-deep {
  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #dbdcdf;
    // 1px solid #dcdfe6
  }
  .el-checkbox__inner:after {
    margin: 1px 0 0 1px;
  }
  .el-checkbox__label {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #58595c;
    line-height: 22px;
  }
  .el-form-item__label {
    margin-bottom: 8px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #26272a;
    line-height: 22px;
  }
  .el-input input {
    height: 52px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .el-input__prefix {
    display: inline-flex;
    align-items: center;
    left: 10px;
  }
  .el-input__suffix {
    display: inline-flex;
    align-items: center;
  }
}
</style>
