<template>
  <div>
    <div class="tab-pane-header">手机号找回密码</div>
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item class="relative" label="验证码" prop="smsCode">
        <el-input v-model="form.smsCode" class="unit-input" placeholder="请输入" maxlength="5" clearable />
        <div class="absolute unit" :class="!!waitTime ? 'disabled' : ''" @click="receiveSmsCode">{{ waitTime ? `重新获取${waitTime}s` : "获取验证码" }}</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleNext">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validateTel, validateRequired, validateLength } from '@/utils/validateRule';
import { sendCode, checkCode } from '@/api/user';

export default {
  name: 'Step1',
  data() {
    return {
      waitTime: undefined,
      form: {
        phone: undefined,
        smsCode: undefined,
      },
      rules: {
        phone: [
          { required: true, validator: validateRequired('请输入手机号码'), trigger: 'blur' },
          { validator: validateTel, trigger: 'blur' },
        ],
        smsCode: [
          { required: true, validator: validateRequired('请输入验证码'), trigger: 'blur' },
          { required: true, validator: validateLength(5), trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    smsCodeInterval() {
      this.waitTime = 60;
      const that = this;
      const timer = setInterval(function() {
        if (that.waitTime > 0) {
          that.waitTime--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    sendSmsCode() {
      const data = {
        phone: this.form.phone,
        userType: 1,
        // scene: 4,
      };
      sendCode(data)
        .then((res) => {
          if (res) {
            this.smsCodeInterval();
          }
        })
        .catch((err) => err);
    },
    receiveSmsCode() {
      if (this.waitTime) return;
      this.$refs.form.validateField(['phone'], (err) => {
        if (!err) {
          this.sendSmsCode();
        }
      });
    },
    handleNext() {
      this.$refs.form.validate((valid) => {
        //         (1)验证码是否正确
        // ①是
        // ②否，提示：“验证码输入错误，请重新输入”
        // (2)验证码是否有效
        // ①是，进入输入新密码页面
        // 1)输入两次新密码，重置密码后，返回登录页面
        // ②否，提示：“验证码失效，请重新发送”
        if (valid) {
          const params = {
            code: this.form.smsCode,
            phone: this.form.phone,
            type: 3, // 类型 1:注册成功通知 2:修改手机号 3:普通验证码 4:修改密码
          };
          checkCode(params).then((res) => {
            if (res) {
              this.$emit('next', this.form);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    padding-bottom: 0;
  }
}
.unit-input {
  ::v-deep {
    .el-input__inner {
      padding-right: 140px;
    }
    .el-input__clear {
      padding-top: 1px;
      padding-right: 120px;
    }
  }
}
.disabled {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.unit {
  border-left: 1px solid #dbdcdf;
  color: #1a6aff;
  cursor: pointer;
  height: 25px;
  line-height: 20px;
  padding: 2px 5px 0 15px;
  margin-top: 6px;
  z-index: 1;
  right: 10px;
  top: 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.tab-pane-header {
  margin-bottom: 10px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #04122b;
  line-height: 22px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
</style>
